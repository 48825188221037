import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Container,
  useMediaQuery,
} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectIsAuthenticating} from '../App/selectors';
import {connect} from 'react-redux';
import {Close} from '@mui/icons-material';
import {setIsAuthenticating} from '../App/actions';
import LoginForm from '../LoginForm';
import RegisterForm from '../RegisterForm';
import {useLocation} from 'react-router-dom';

const AuthDialog = ({isAuthenticating, dispatch}) => {
  const [isRegistering, setIsRegistering] = useState(true);
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (isAuthenticating) {
      dispatch(setIsAuthenticating(false));
    }
  }, [location]);

  return (
    <Dialog fullScreen open={isAuthenticating || false}>
      <DialogContent style={{backgroundColor: '#f1f1f1'}}>
        <Container style={{position: 'relative'}} maxWidth="sm">
          <div style={{height: 100}} />
          <IconButton
            style={{
              position: 'absolute',
              right: 16,
              marginTop: isDesktop ? 44 : 24,
            }}
            onClick={() => dispatch(setIsAuthenticating(false))}>
            <Close />
          </IconButton>
          {isRegistering ? (
            <RegisterForm
              noRedirect
              hideApply
              onToggleAuth={() => setIsRegistering(false)}
            />
          ) : (
            <LoginForm onToggleAuth={() => setIsRegistering(true)} />
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = createStructuredSelector({
  isAuthenticating: makeSelectIsAuthenticating(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthDialog);
