import React, {useEffect, useState} from 'react';
import DoodleGrid from '../DoodleGrid';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';

const FYPDoodles = () => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    fetchDoodles();
  }, []);

  const fetchDoodles = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'doodles'),
        where('public', '==', true),
        where('deleted', '==', false),
        orderBy('fyp', 'desc'),
        limit(12),
      ),
    );

    const _doodles = [];

    querySnapshot.forEach(doc => {
      _doodles.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setDoodles(_doodles);
  };

  console.log(doodles);

  return <DoodleGrid doodles={doodles} />;
};

export default FYPDoodles;
