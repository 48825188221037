import React, {useEffect, useRef, useState} from 'react';
import {collection, getDocs, limit, orderBy, query} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Box, Stack, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const BLUE = '#071277';
const YELLOW = '#D69F4C';
const LIGHT_GREY = '#DBDBDB';

const ContestsRow = () => {
  const [contests, setContests] = useState([]);

  useEffect(() => {
    getDocs(
      query(collection(db, 'contests'), orderBy('title', 'desc'), limit(4)),
    ).then(snap => {
      setContests(snap.docs);
    });
  }, []);

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Contests</Typography>
      <div className="scrolling-wrapper-container">
        <Box className="scrolling-wrapper">
          {contests.map(contest => (
            <ContestSquare key={contest.id} contest={contest} />
          ))}
        </Box>
      </div>
    </Stack>
  );
};

const ContestSquare = ({contest}) => {
  const isLive = useRef(contest.data().state === 'live').current;
  const isPending = useRef(contest.data().state === 'pending').current;
  const isCompleted = useRef(contest.data().state === 'completed').current;

  const navigate = useNavigate();

  const onClick = () => {
    if (isPending) {
      alert('Contest is not live yet');
    } else if (isLive) {
    } else if (isCompleted) {
      navigate(`/contests/${contest.id}`);
    }
  };

  return (
    <Stack spacing={2}>
      <div
        onClick={onClick}
        style={{
          width: 260,
          height: 260,
          backgroundColor: isLive ? BLUE : LIGHT_GREY,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          marginRight: 16,
          cursor: 'pointer',
        }}>
        <Typography variant="h1">
          <strong style={{color: isLive ? YELLOW : '#C7C7C7'}}>
            ${contest.data().prize}
          </strong>
        </Typography>
      </div>
      <Stack style={{marginTop: 0, opacity: isLive ? 1 : 0.6}}>
        <Typography textAlign="center">{contest.data().description}</Typography>
        <Typography textAlign="center">{contest.data().title}</Typography>
        <Typography textAlign="center">Ended</Typography>
      </Stack>
    </Stack>
  );
};

export default ContestsRow;
